import { useLocation } from "react-router-dom";
import axios from "axios";
import { Payout } from "../../screens/payout/hook";
import {
  Box,
  Container,
  Card,
  Stack,
  Typography,
  Button,
  Snackbar,
} from "@mui/material";
import { Icon } from "@iconify/react";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Loader from "../../components/loader";

const Index = () => {
  const {
    openSnackbar,
    snackbarOpen,
    closeSnackbar,
    snackbarSeverity,
    snackbarMessage,
    dataMerchant,
    dataCard,
    loading,
  } = Payout();

  const location = useLocation();
  const {
    selectedRows,
    nonSelectedRows,
    totalApprovedAmountRows,
    totalRejectedAmountRows,
  } = location.state;


  const generateAlert = async () => {
    const problemUrl = "https://payments.qentaz.com/v1/help-report";
    const alertInfo = {
      text: `*ALGUIEN REPORTÓ UN PROBLEMA EN EL WEB-VIEW DISPERSIÓN CONCILIADA :fire-elmo:*\n<!channel>\n---------------\n*Cliente*: ${dataMerchant.merchant_phone}\n*Fecha*: ${new Date()}\n---------------`,
      parse: false,
      mrkdwn: true,
    };
    try {
      await axios.post(problemUrl, { payload: alertInfo });
      openSnackbar("Hemos notificado al equipo de soporte", "success");
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
      openSnackbar("Error al enviar el mensaje", "error");
    }
  };

  const format_number = (x: number) => {
    if (typeof x !== "number" || isNaN(x)) {
      return "0";
    }
    const formattedNumber = x.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    });
    return formattedNumber
      .replace(/\./g, "@")
      .replace(/,/g, ".")
      .replace(/@/g, ",");
  };

  const toDashboard = () => {
    window.location.href = `https://dashboard.qentaz.com/`;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container
          sx={{
            backgroundColor: dataMerchant.secondary_color_brand,
            margin: 0,
            padding: { xs: 0, md: "1rem" },
            minWidth: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: { xs: 0, md: "16vh" }
          }}
        >
          <Box
            sx={{
              width: "90%",
              display: { xs: "flex", md: "none" },
              margin: "2rem",
            }}
          >
            <Box
              sx={{
                width: "13%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Box
                component="img"
                sx={{
                  width: 70,
                }}
                alt="Logo"
                src={dataMerchant.logo}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                margin: "0 0 0 2rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "20px", md: "22px" },
                  color: dataMerchant.main_color_brand,
                  marginBottom: "0",
                  fontWeight: "600",
                  fontFamily: "Red Hat Display",
                }}
              >
                ¡Resumen de la dispersión!
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "14px" },
                  color: "gray",
                  fontFamily: "Red Hat Display",
                }}
              >
                ID del proceso: {dataCard.process_id}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "14px", md: "16px" },
                  color: "black",
                  fontFamily: "Red Hat Display",
                }}
              >
                A continuacion podrás verificar el resumen de la dispersión.
              </Typography>
            </Box>
          </Box>
          <Card
            sx={{
              width: { xs: "100vw", md: "60vw" },
              height: { xs: "auto", md: "auto" },
              padding: "1rem",
              borderRadius: { xs: "0", md: "16px" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: { xs: "none", md: "flex" },
                margin: "1rem 0 1rem 1rem",
              }}
            >
              <Box
                sx={{
                  width: "13%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: 90,
                  }}
                  alt="Logo"
                  src={dataMerchant.logo}
                />
              </Box>
              <Box
                sx={{
                  width: "70%",
                  margin: "0 2rem 1rem 2rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "22px",
                    color: dataMerchant.main_color_brand,
                    marginBottom: "0",
                    fontWeight: "600",
                    fontFamily: "Red Hat Display",
                  }}
                >
                  ¡Resumen de la dispersión!
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "gray",
                    fontFamily: "Red Hat Display",
                  }}
                >
                  ID del proceso: {dataCard.process_id}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "black",
                    fontFamily: "Red Hat Display",
                  }}
                >
                  A continuacion podrás verificar el resumen de la dispersión.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                gap: "0",
              }}
            >
              <Box
                sx={{
                  width: { xs: "50%", md: "56%" },
                  borderRight: "1px solid gray",
                  paddingRight: "2%",
                  margin: { xs: "4%", md: 0 }
                }}
              >
                <Box
                  sx={{
                    margin: "4% 0 12% 10%",
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      width: "36%",
                    }}
                  >
                    <Typography
                      fontSize="18px"
                      fontWeight={600}
                      sx={{
                        margin: "0",
                        fontFamily: "Red Hat Display",
                        color: "#0F5C38",
                      }}
                    >
                      Aprobadas:
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Red Hat Display",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      CANTIDAD:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Red Hat Display",
                        color: "#637381",
                      }}
                    >
                      {selectedRows.length}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "32%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Red Hat Display",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      MONTO:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Red Hat Display",
                        color: "#637381",
                      }}
                    >
                      {`$${format_number(totalApprovedAmountRows)}`}{" "}
                      {dataCard.currency}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    margin: "4% 0 4% 10%",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: { xs: "column", md: "row" },
                    paddingBottom: "3%",
                  }}
                >
                  <Box
                    sx={{
                      width: "36%",
                    }}
                  >
                    <Typography
                      fontSize="18px"
                      fontWeight={600}
                      sx={{
                        margin: "0",
                        fontFamily: "Red Hat Display",
                        color: "#B71D18",
                      }}
                    >
                      Rechazadas:
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Red Hat Display",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      CANTIDAD:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Red Hat Display",
                        color: "#637381",
                      }}
                    >
                      {nonSelectedRows.length}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Red Hat Display",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      MONTO:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Red Hat Display",
                        color: "#637381",
                      }}
                    >
                      {`$${format_number(totalRejectedAmountRows)}`}{" "}
                      {dataCard.currency}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ width: { xs: "50%", md: "36%" }, margin: { xs: "4%", md: 0 } }}>
                <Box
                  sx={{
                    width: "100%",
                    marginTop: "6%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Red Hat Display",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    DESTINATARIO:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Red Hat Display",
                      color: "#637381",
                    }}
                  >
                    {dataCard.receiver}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    marginTop: "8%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Red Hat Display",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    CUENTA DESTINO:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Red Hat Display",
                      color: "#637381",
                    }}
                  >
                    {dataCard.bank_receiver}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "92%" },
                display: "flex",
                justifyContent: "flex-end",
                margin: { xs: "6% 0", md: "0 0 2% 6%" },
              }}
            >
              <LoadingButton
                onClick={toDashboard}
                variant="contained"
                sx={{
                  border: "none",
                  borderRadius: "8px",
                  backgroundColor: dataMerchant.main_color_brand,
                  color: "white",
                  fontSize: "14px",
                  textTransform: "none",
                  width: "9rem",
                  height: "2.5rem",
                  fontFamily: "Red Hat Display",
                  marginRight: "2%",
                  "&:hover": {
                    backgroundColor: dataMerchant.secondary_color_brand,
                    border: "none",
                    color: dataMerchant.main_color_brand,
                  },
                }}
              >
                Ir al Dashboard
              </LoadingButton>
            </Box>
          </Card>

          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-between" },
              flexDirection: { xs: "column", md: "row" },
              width: { xs: "100%", md: "60vw" },
              marginTop: "1rem",
              alignItems: "center",
            }}
          >
            <Box>
              <Stack direction="row" sx={{ display: { xs: "none", md: "flex" } }}>
                <Button
                  endIcon={`Ayuda`}
                  sx={{
                    textTransform: "none",
                    color: "gray",
                    fontSize: { xs: "13px", md: "14px" },
                    textDecoration: "underline",
                    padding: 0
                  }}
                  onClick={generateAlert}
                >
                  <Icon icon="ic:baseline-whatsapp" width="22" height="22" />
                </Button>
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  color: "gray",
                  fontSize: "16px",
                  fontFamily: "Red Hat Display",
                  fontWeight: "500",
                }}
              >
                Con la tecnología de
              </Typography>
              <Box
                component="img"
                sx={{
                  width: 60,
                  marginLeft: "4px",
                  marginTop: "-6px"
                }}
                alt="Logo"
                src="https://trazo-co.s3.amazonaws.com/logos/Logo+Trazo+Color+Secundario+100px.svg"
              />
            </Box>
          </Box>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={closeSnackbar}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={closeSnackbar}
              severity={snackbarSeverity}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
        </Container>
      )}
    </>
  );
};

export default Index;
