import "./App.css";
import { router } from "./router/src";
import { RouterProvider } from "react-router-dom";
import NotFound from "./sections/notFound404";
import ThemeProvider from "../src/mui-components/theme";
import { SettingsProvider } from "./mui-components/settings";
import { GlobalStyle } from "./mui-components/styled-components";
import { SnackbarProvider } from "../src/mui-components/snackbar"
import ErrorBoundary from "./components/errorBoundary";

function App() {
  const pathSegments = window.location.pathname.split("/").filter(Boolean);
  const isConciled = pathSegments[0] === "conciliada" || pathSegments[0] === "simple" && pathSegments.length > 1;

  return (
    <>
      <ErrorBoundary>
        <SettingsProvider
          defaultSettings={{
            themeMode: "light", // 'light' | 'dark'
            themeDirection: "ltr", //  'rtl' | 'ltr'
            themeContrast: "default", // 'default' | 'bold'
            themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <SnackbarProvider>
              <GlobalStyle />
              {isConciled ? <RouterProvider router={router} /> : <NotFound />}
            </SnackbarProvider>
          </ThemeProvider>
        </SettingsProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
