import { createBrowserRouter } from "react-router-dom";
import Home from "../../sections/home";
import Payout from "../../sections/payout";
import Success from "../../sections/success";
import NotFound from "../../sections/notFound404";
import SimplePayout from "../../sections/simplePayout";

import { ROUTES, getRouteRoot } from "../config";

const {
  BASE_URL,
  SUCCESS
} = ROUTES;

const token = window.location.pathname.replace("/", "");
const getRouteComponent = (): JSX.Element => {
  const pathname = window.location.pathname.toLowerCase();

  if (pathname.includes("conciliada")) return <Payout />;
  if (pathname.includes("simple")) return <SimplePayout />;
  
  return <Home />;
};
const root = getRouteRoot({ token });
export const router = createBrowserRouter(
  [
    {
      path: BASE_URL,
      element: getRouteComponent(),
    },
    {
      path: SUCCESS,
      element: <Success />,
    },
    {
      path: "*", 
      element: <NotFound />,
    },
  ],
  { basename: root },
);
