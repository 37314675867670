import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

const SimpleApprovedModal = ({
  open,
  close,
  first_name,
  total_payouts,
  total_amount,
  name_receiver,
  bank_receiver,
  currency,
  process_id,
  toOtp,
  main_color_brand,
  secondary_color_brand
}: any) => {
  return (
    <Modal open={open} onClose={close}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "64vw", md: 500 },
          bgcolor: "background.paper",
          borderRadius: 3,
          boxShadow: 24,
          padding: { xs: "0 2% 2% 2%", md: "0 1% 1% 1%" },
        }}
      >
        <Box
          sx={{
            width: "96%",
            margin: "1% 0 0 0",
            padding: 0,
            textAlign: "end",
            display: "flex",
            justifyContent: "end",
            position: "absolute"
          }}>
          <Button
            onClick={close}
            sx={{
              color: "gray",
              minWidth: "0"
            }}
          >
            <CloseIcon fontSize="medium" sx={{ margin: "0" }} />
          </Button>
        </Box>
        <Box
          sx={{
            margin: "5% 2% 2% 2%",
            width: "92%",
            padding: "2%",
            display: "flex",
            borderRadius: "8px",
            color: " var(--dark-light-info, #006C9C)",
            backgroundColor: "var(--info-8, #00B8D914)",
            border: "1px solid var(--info-16, #00B8D929)",
          }}
        >
          <ErrorIcon
            sx={{
              width: "18px",
              margin: "0.5% 2% 0 0",
            }}
          />
          <Typography
            sx={{
              width: "100%",
              margin: "0",
              fontSize: { xs: "16px", md: "18px" },
              fontWeight: "500",
              fontFamily: "Red Hat Display",
              justifyContent: "space-between",
            }}
          >
            Verifica tu información
          </Typography>
        </Box>

        <Typography
          fontWeight={400}
          sx={{
            margin: "4% 2% 5% 2%",
            fontFamily: "Red Hat Display",
            color: "#637381",
            fontSize: { xs: "14px", md: "16px" }
          }}
        >
          Detalles de la dispersión a aprobar:
        </Typography>
        <Box
          sx={{
            margin: "2% 2% 4% 2%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" }
          }}
        >
          {total_payouts.length > 1 && (
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "14px" },
                  fontFamily: "Red Hat Display",
                  fontWeight: "600",
                  color: "#637381",
                }}
              >
                CANTIDAD:
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "14px" },
                  fontFamily: "Red Hat Display",
                  color: "#637381",
                }}
              >
                {total_payouts}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                fontWeight: "600",
                color: "#637381",
              }}
            >
              MONTO:
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                color: "#637381",
              }}
            >
              {total_amount} {currency}
            </Typography>
          </Box>
        </Box>
        {total_payouts == 1 ? (
          <Box
            sx={{
              margin: "2% 2% 4% 2%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" }
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "14px" },
                  fontFamily: "Red Hat Display",
                  fontWeight: "600",
                  color: "#637381",
                }}
              >
                DESTINATARIO:
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "14px" },
                  fontFamily: "Red Hat Display",
                  color: "#637381",
                  marginBottom: { xs: "4%", md: 0 }
                }}
              >
                {name_receiver}
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "14px" },
                  fontFamily: "Red Hat Display",
                  fontWeight: "600",
                  color: "#637381",
                }}
              >
                CUENTA DESTINO:
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "14px" },
                  fontFamily: "Red Hat Display",
                  color: "#637381",
                }}
              >
                {bank_receiver}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                fontWeight: "600",
                color: "#637381",
              }}
            >
              APROBADOR:
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                color: "#637381",
              }}
            >
              {first_name}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            margin: "2%",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "12px", md: "14px" },
              fontFamily: "Red Hat Display",
              fontWeight: "600",
              color: "#637381",
            }}
          >
            ID DEL PROCESO:
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "12px", md: "14px" },
              fontFamily: "Red Hat Display",
              color: "#637381",
            }}
          >
            {process_id}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "98%",
            display: "flex",
            justifyContent: "end",
            marginTop: "1%",
            marginBottom: "2%"
          }}
        >
          <Button
            variant="contained"
            onClick={close}
            sx={{
              maxHeight: "40px",
              minHeight: "35px",
              backgroundColor: secondary_color_brand,
              mt: 2,
              width: { xs: "30%", md: "16%" },
              textTransform: "none",
              fontFamily: "Red Hat Display",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: "400",
              color: main_color_brand,
              borderRadius: "8px",
              height: { xs: "4vh", md: "5vh" },
              marginRight: "3%",
              '&:hover': {
                backgroundColor: "gray",
                border: "none",
                color: "white"
              },
            }}
          >
            Volver
          </Button>
          <Button
            variant="contained"
            onClick={toOtp}
            sx={{
              maxHeight: "40px",
              minHeight: "35px",
              backgroundColor: main_color_brand,
              mt: 2,
              width: { xs: "30%", md: "16%" },
              textTransform: "none",
              fontFamily: "Red Hat Display",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: "400",
              borderRadius: "8px",
              height: { xs: "4vh", md: "5vh" },
              '&:hover': {
                backgroundColor: "gray",
                border: "none",
                color: "white"
              },
            }}
          >
            Continuar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SimpleApprovedModal;
