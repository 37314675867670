import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import { decrypted } from "../../../api/crypto";
import { decode } from "../../../api/jwt/index";
import { READ_PAYOUT_LIST } from "../../../api/readTable";
import { READ_PAYOUT_SUMMARY } from "../../../api/readCard";
import { READ_PAYOUT_MERCHANT } from "../../../api/readMerchant";
import { READ_OTP_API } from "../../../api/otpRead";
import { CREATE_ACCESS_API } from "../../../api/process";
import { token } from "../../../api/crypto/token";
import { CREATE_APPROVAL_RECONCILED } from "../../../api/approvalReconciled";
import { CREATE_CHECK_OTP_API } from "../../../api/otpCreateCheck";
import { READ_LOGIN_API } from "../../../api/createLogin";
import { ROUTES } from "../../../router/config";
import { READ_CHECK_WEBVIEW_API } from "../../../api/readCheck";

const Payout = () => {
  interface DataCard {
    receiver: string;
    process_id: string;
    total_amount: string;
    total_payouts: number;
    bank_receiver: string;
    currency: string;
  }

  interface RowData {
    id: number;
    external_reference: string;
    description: string;
    reference_one: string;
    reference_two: string;
    reference_three: string;
    amount: string;
    merchant_name: string;
    payer_name: string;
  }

  interface DataMerchant {
    merchant_name: string;
    first_name: string;
    logo: string;
    main_color_brand: string;
    secondary_color_brand: string;
    payment_id: string;
    merchant_phone: string;
    merchant_email: string;
    status_detail: string;
  }

  const [dataCard, setDataCard] = useState<DataCard>({
    receiver: "",
    process_id: "",
    total_amount: "",
    total_payouts: 0,
    bank_receiver: "",
    currency: "",
  });
  const [dataMerchant, setDataMerchant] = useState<DataMerchant>({
    merchant_name: "",
    first_name: "",
    logo: "",
    main_color_brand: "",
    secondary_color_brand: "",
    payment_id: "",
    merchant_phone: "",
    merchant_email: "",
    status_detail: "",
  });
  const [dataTable, setDataTable] = useState<RowData[]>([]);
  const [loading, setLoading] = useState(true);
  const [otp, setOtp] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [failedAttempts, setFailedAttempts] = useState("");
  const [selectionModel, setSelectionModel] = useState<any>([]);
  const [nonSelectedRows, setNonSelectedRows] = useState<RowData[]>([]);
  const [selectedRows, setSelectedRows] = useState<RowData[]>([]);
  const [totalApprovedAmountRows, setTotalApprovedAmountRows] =
    useState<number>(0);
  const [totalRejectedAmountRows, setTotalRejectedAmountRows] =
    useState<number>(0);
  const [isChecked, setIsChecked] = useState(false);
  const [tx_reject_reason, setTx_reject_reason] = useState("");
  const [tx_reject_channel, setTx_reject_channel] = useState("");
  const [tx_reject_channel_value, setTx_reject_channel_value] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const [snackbarCodeOpen, setSnackbarCodeOpen] = useState(false);
  const [snackbarCodeMessage, setSnackbarCodeMessage] = useState("");
  const [snackbarCodeSeverity, setSnackbarCodeSeverity] = useState<
    "success" | "error"
  >("success");

  const [snackbarRejectedOpen, setSnackbarRejectedOpen] = useState(false);
  const [snackbarRejectedMessage, setSnackbarRejectedMessage] = useState("");
  const [snackbarRejectedSeverity, setSnackbarRejectedSeverity] = useState<
    "success" | "error"
  >("success");

  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [blockConciliation, setBlockConciliation] = useState(false);

  const [isTotalRejectedModalOpen, setIsTotalRejectedModalOpen] =
    useState(false);
  const [isTotalApprovedModalOpen, setIsTotalApprovedModalOpen] =
    useState(false);
  const [isPartialApprovedModalOpen, setIsPartialApprovedModalOpen] =
    useState(false);

  const [contactData, setContactData] = useState<{
    id: string;
    type: string;
    userId?: string;
  }>();
  const [id, setId] = useState(
    window.location.pathname.replace("/conciliada/", "")
  );

  const navigate = useNavigate();

  const closeOtpModal = () => {
    setIsOtpModalOpen(false);
  };
  const closeTotalRejectedModal = () => {
    setIsTotalRejectedModalOpen(false);
  };
  const closeTotalApprovedModal = () => {
    setIsTotalApprovedModalOpen(false);
  };
  const closePartialApprovedModal = () => {
    setIsPartialApprovedModalOpen(false);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const openSnackbar = (message: string, severity: "success" | "error") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const closeSnackbarRejected = () => {
    setSnackbarRejectedOpen(false);
  };

  const openSnackbarRejected = (
    message: string,
    severity: "success" | "error"
  ) => {
    setSnackbarRejectedMessage(message);
    setSnackbarRejectedSeverity(severity);
    setSnackbarRejectedOpen(true);
  };

  const handleChange = (newValue: string) => {
    setOtp(newValue);
  };

  const format_number = (x: number) => {
    if (typeof x !== "number" || isNaN(x)) {
      return "0";
    }
    const formattedNumber = x.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    });
    return formattedNumber
      .replace(/\./g, "@")
      .replace(/,/g, ".")
      .replace(/@/g, ",");
  };

  const callsRef = useRef({
    webview: false,
    summary: false,
    list: false,
    merchant: false,
  });

  const [isOtpChecked, setIsOtpChecked] = useState(false);

  const fetchData = useCallback(async () => {
    if (!id || isOtpChecked) return;

    setLoading(true);

    if (!callsRef.current.webview) {
      callsRef.current.webview = true;
      try {
        const userInfoRequest = await READ_CHECK_WEBVIEW_API({ id });

        if (userInfoRequest.code === "Q200") {
          setBlockConciliation(false);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error("Error en READ_CHECK_WEBVIEW_API:", error.response?.data || error.message);
          if (decrypted(error.response?.data?.data)?.code === "Q300") {
            setBlockConciliation(true);
          }
        } else {
          console.error("Error desconocido en READ_CHECK_WEBVIEW_API:", error);
        }
      }
    }

    try {
      if (!callsRef.current.summary) {
        callsRef.current.summary = true;
        const summaryData = await READ_PAYOUT_SUMMARY({ id });
        const firstSummaryData = summaryData?.[0] || {};
        setDataCard({
          receiver: _.get(firstSummaryData, "name_receiver", "-"),
          process_id: _.get(firstSummaryData, "payout_process_id", "-"),
          total_amount: _.get(firstSummaryData, "total_amount", 0),
          total_payouts: _.get(firstSummaryData, "total_payouts", 0),
          bank_receiver: _.get(firstSummaryData, "bank_receiver", "-"),
          currency: _.get(firstSummaryData, "currency", "COP"),
        });
      }

      if (!callsRef.current.list) {
        callsRef.current.list = true;
        const listData = await READ_PAYOUT_LIST({ id });
        let parsedDataTable = [];
        try {
          parsedDataTable = typeof listData === "string" ? JSON.parse(listData) : listData;
        } catch (error) {
          console.error("Error al parsear dataTable:", error);
        }
  
        setDataTable(
          parsedDataTable.map((row: any, index: number) => ({
            id: index + 1,
            external_reference: row.external_reference || "-",
            description: row.description || "-",
            amount: `$${format_number(parseFloat(row.amount))}` || "0",
            reference_one: row.reference_one || "-",
            reference_two: row.reference_two || "-",
            reference_three: row.reference_three || "-",
            merchant_name: row.merchant_name || "-",
            payer_name: row.payer_name || "-",
          }))
        );
      }
  
      
      if (!callsRef.current.merchant) {
        callsRef.current.merchant = true;
        const merchantData = await READ_PAYOUT_MERCHANT({ id });
        const firstMerchantData = merchantData?.[0] || {};
        setDataMerchant({
          merchant_name: _.get(firstMerchantData, "business_name", "-"),
          first_name: _.get(firstMerchantData, "first_name", "-"),
          logo: _.get(firstMerchantData, "logo", "-"),
          main_color_brand: _.get(firstMerchantData, "main_color_brand", "-"),
          secondary_color_brand: _.get(firstMerchantData, "secondary_color_brand", "-"),
          payment_id: _.get(firstMerchantData, "payment_id", "-"),
          merchant_phone: _.get(firstMerchantData, "phone", "-"),
          merchant_email: _.get(firstMerchantData, "user_email", "-"),
          status_detail: _.get(firstMerchantData, "status_detail", "inactive"),
        });
  
        setContactData({
          id: firstMerchantData.phone || firstMerchantData.user_email,
          type: firstMerchantData.phone ? "whatsapp" : "email",
        });
      }
    } catch (error) {
      console.error("Error en las llamadas API:", error);
      window.location.href = "/not-found";
    }

    setLoading(false);
    setIsOtpChecked(true);
  }, [id, isOtpChecked]);

  useEffect(() => {
    fetchData();

    const totalSelectedRows = selectedRows.reduce((sum, row) => {
      const amountNumber = parseFloat(
        row.amount
          .replace(/[\$]/g, "") // Remover el símbolo de dólar
          .replace(/\./g, "") // Remover puntos como separadores de miles
          .replace(/,/g, ".") // Reemplazar comas decimales con puntos
      );
      return sum + amountNumber;
    }, 0);
    setTotalApprovedAmountRows(totalSelectedRows);

    const unSelectedRow = dataTable.filter(
      (row) => !selectedRows.some((selectedRow) => selectedRow.id === row.id)
    );
    setNonSelectedRows(unSelectedRow);
    const total = unSelectedRow.reduce((sum, row) => {
      const amountNumber = parseFloat(
        row.amount
          .replace(/[\$]/g, "") // Remover el símbolo de dólar
          .replace(/\./g, "") // Remover puntos como separadores de miles
          .replace(/,/g, ".") // Reemplazar comas decimales con puntos
      );
      return sum + amountNumber;
    }, 0);
    setTotalRejectedAmountRows(total);
  }, [fetchData, selectedRows]);

  const handleSelectionModelChange = (newSelection: any) => {
    setSelectionModel(newSelection);
    const selectedRowsData = newSelection.map((id: number) =>
      dataTable.find((row) => row.id === id)
    );
    setSelectedRows(selectedRowsData);
  };

  const createOtp = async (id: string) => {
    const otpRequest = READ_OTP_API({
      id,
      type: "confirmation",
      description: "success transactions payout conciliated approval",
    });
    const accessToken = token({
      id,
    }).toString();
    return await CREATE_ACCESS_API(otpRequest, accessToken);
  };

  const onSubmit = async () => {
    try {
      const otpData =
        dataMerchant.merchant_phone
          ? await createOtp(String(dataMerchant.merchant_phone))
          : await createOtp(dataMerchant.merchant_email);
      const otpDecData = decrypted(otpData?.data);

      localStorage.setItem(
        "__uid",
        JSON.stringify({
          _contact_id:
            dataMerchant.merchant_phone || dataMerchant.merchant_email,
        })
      );
      return;

    } catch (error) {
      console.error("Error en el envío de OTP:", error);
      //confirm.onFalse();
    }
  };

  const checkOtp = async () => {
    setIsOtpChecked(true);
    try {
       // Bloqueamos fetchData después de OTP

      const accessToken = token({
        id: dataMerchant.merchant_phone || dataMerchant.merchant_email,
      }).toString();

      const request_contact = CREATE_CHECK_OTP_API({
        ...contactData,
        otp,
      });

      const {
        data: { data: OtpDataHash },
      } = await CREATE_ACCESS_API(request_contact, accessToken);

      const OtpData = decrypted(OtpDataHash);

      if (OtpData) {
        localStorage.setItem("_access_token", OtpData?.token);

        


        navigate(ROUTES.SUCCESS, {
          state: {
            selectedRows,
            nonSelectedRows,
            totalApprovedAmountRows,
            totalRejectedAmountRows,
            selectionModel,
          },
          replace: true,
        });

        const ipData = await axios.get("https://api.ipify.org?format=json");
        const ipAddress = ipData.data.ip;

        const postData = {
          id: id,
          contact_ip: ipAddress,
          business_id: dataMerchant.payment_id,
          contact_auth: otp,
          tx_approve_list: selectedRows.map((row) => row.external_reference),
          tx_reject_list: nonSelectedRows.map((row) => row.external_reference),
          tx_approve_amount: totalApprovedAmountRows
            ? totalApprovedAmountRows
            : 0,
          ...(tx_reject_reason ? { tx_reject_reason: tx_reject_reason } : {}),
          ...(tx_reject_channel
            ? { tx_reject_channel: tx_reject_channel }
            : {}),
          ...(tx_reject_channel_value
            ? { tx_reject_channel_value: tx_reject_channel_value }
            : {}),
        };

        try {
          const approvalRequest = CREATE_APPROVAL_RECONCILED(postData);
          const data = await CREATE_ACCESS_API(approvalRequest, accessToken);
          console.log("Datos enviados con éxito");
        } catch (error) {
          const e = error as any;
          console.error("Error al enviar los datos:", e.response?.data?.data);
        }
        setLoading(true);
      } else if (otp === "") {
        //confirm.onFalse();
        setErrorMsg("El código no fue ingresado");
      } else {
        //confirm.onFalse();
        setErrorMsg(OtpData.message || "El código ingresado no es válido");
        handleChange("");
        if (OtpData.errorCode === "BLOCKED") {
          //setIsBlocked(true);
        }
      }
    } catch (error) {
      const ipData = await axios.get("https://api.ipify.org?format=json");

      //confirm.onFalse();
      setFailedAttempts(
        "Has hecho un nuevo intento fallido, al completar tres se hará un bloqueo temporal"
      );
      setErrorMsg("El código ingresado no es válido");
      handleChange("");
      const { data: loginData } = await READ_LOGIN_API(
        contactData?.id,
        ipData?.data.ip,
        "KEY"
      );

      const tokenData = decode(loginData?.token?.token);

      const intentLogin = tokenData?.intent;
      const blockTime = tokenData?.block_time;
      const date = new Date(blockTime);
      const localDate = date.toLocaleDateString();
      const localTime = date.toLocaleTimeString().replace(/.$/, "");

      if (tokenData?.block_time) {
        const dateChecker = new Date().toISOString();
        if (new Date(tokenData?.block_time) > new Date(dateChecker)) {
          const blockTime = tokenData?.block_time;
          //setIsBlocked(true);
          //AQUI BLOQUEADO POR TIEMPO
        }
      }

      if (tokenData?.intent === 3 && tokenData?.block_time) {
        setErrorMsg(
          `Has sido bloqueado temporalmente por exceso de intentos fallidos, y podrás intentarlo nuevamente el ${localDate} a las ${localTime}`
        );
        setFailedAttempts("");
      }

      if (tokenData?.intent > 3 && tokenData?.block_time) {
        setErrorMsg(
          `Has sido bloqueado temporalmente por exceso de intentos fallidos, y podrás intentarlo nuevamente el ${localDate} a las ${localTime}`
        );
        setFailedAttempts("");
      }

      //MENSAJE BLOQUEADO
    }
  };

  const rejectPayout = async () => {
    navigate(ROUTES.SUCCESS, {
      state: {
        selectedRows,
        nonSelectedRows,
        totalApprovedAmountRows,
        totalRejectedAmountRows,
        selectionModel,
      },
      replace: true,
    });

    const ipData = await axios.get("https://api.ipify.org?format=json");
    const ipAddress = ipData.data.ip;

    const postData = {
      id: id,
      contact_ip: ipAddress,
      business_id: dataMerchant.payment_id,
      contact_auth: otp ? otp : "reject",
      tx_approve_list: selectedRows.map((row) => row.external_reference),
      tx_reject_list: nonSelectedRows.map((row) => row.external_reference),
      tx_approve_amount: totalApprovedAmountRows ? totalApprovedAmountRows : 0,
      ...(tx_reject_reason ? { tx_reject_reason: tx_reject_reason } : {}),
      ...(tx_reject_channel ? { tx_reject_channel: tx_reject_channel } : {}),
      ...(tx_reject_channel_value
        ? { tx_reject_channel_value: tx_reject_channel_value }
        : {}),
    };

    try {
      const accessToken = token({
        id: dataMerchant.merchant_phone || dataMerchant.merchant_email,
      }).toString();
      const approvalRequest = CREATE_APPROVAL_RECONCILED(postData);
      const data = await CREATE_ACCESS_API(approvalRequest, accessToken);
      console.log("Datos enviados con éxito");
    } catch (error) {
      const e = error as any;
      console.error("Error al enviar los datos:", e.response?.data?.data);
    }
    setIsTotalRejectedModalOpen(false);
    setLoading(true);
  };

  const openSnackbarCode = (message: string, severity: "success" | "error") => {
    setSnackbarCodeMessage(message);
    setSnackbarCodeSeverity(severity);
    setSnackbarCodeOpen(true);
  };
  const closeSnackbarCode = () => {
    setSnackbarCodeOpen(false);
  };

  const handledResend = () => {
    try {
      openSnackbarCode("Hemos enviado nuevamente tu código", "success");
      setDisabled(true);
      onSubmit();
      setTimeout(() => {
        setDisabled(false);
      }, 6000);
    } catch (error) {
      console.error("Error al enviar tu código:", error);
      openSnackbar("Error al enviar tu código", "error");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      checkOtp();
    }
  };

  return {
    snackbarOpen,
    snackbarMessage,
    openSnackbar,
    closeSnackbar,
    snackbarSeverity,
    setSnackbarSeverity,
    isOtpModalOpen,
    isTotalRejectedModalOpen,
    isTotalApprovedModalOpen,
    isPartialApprovedModalOpen,
    setIsOtpModalOpen,
    setIsTotalRejectedModalOpen,
    setIsTotalApprovedModalOpen,
    setIsPartialApprovedModalOpen,
    closeOtpModal,
    closeTotalRejectedModal,
    closeTotalApprovedModal,
    closePartialApprovedModal,
    otp,
    setOtp,
    disabled,
    setDisabled,
    blockConciliation,
    //createOtp,
    handleChange,
    checkOtp,
    handleKeyDown,
    handledResend,
    snackbarCodeOpen,
    closeSnackbarCode,
    snackbarCodeSeverity,
    snackbarCodeMessage,
    snackbarRejectedOpen,
    closeSnackbarRejected,
    openSnackbarRejected,
    snackbarRejectedSeverity,
    snackbarRejectedMessage,
    dataCard,
    dataTable,
    selectionModel,
    setSelectionModel,
    selectedRows,
    setSelectedRows,
    nonSelectedRows,
    setNonSelectedRows,
    totalApprovedAmountRows,
    setTotalApprovedAmountRows,
    totalRejectedAmountRows,
    setTotalRejectedAmountRows,
    handleSelectionModelChange,
    isChecked,
    setIsChecked,
    dataMerchant,
    onSubmit,
    errorMsg,
    rejectPayout,
    loading,
    setLoading,
    tx_reject_reason,
    setTx_reject_reason,
    tx_reject_channel,
    setTx_reject_channel,
    tx_reject_channel_value,
    setTx_reject_channel_value,
  };
};

export { Payout };
