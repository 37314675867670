import { API_BASE_URL, SIMPLE_LIST_QUERY } from "../config";
import { CREATE_DATA } from "../api-call";

const readSimplePayoutList = ({
  id
}: {
    id: string
}) => {

  const url = `${API_BASE_URL}${SIMPLE_LIST_QUERY}`;
  const data = {
    id: id
  }

  return CREATE_DATA({ url, data });
};

export { readSimplePayoutList as READ_SIMPLE_PAYOUT_LIST };