import { HOST_ROUTES } from "../config";

const approvalReconciled = (data?: any) => {
  const { REPORT } = HOST_ROUTES;

  const request = {
    host: REPORT.host,
    method: "post",
    path: `${REPORT.paths.reconciled_approval_layout}`,
    body: { ...data },
  };

  return request;
};

export { approvalReconciled as CREATE_APPROVAL_RECONCILED };