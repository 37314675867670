import React, { forwardRef } from 'react';
import { Icon } from '@iconify/react';
import Box, { BoxProps } from '@mui/material/Box';
import { IconifyProps } from './types';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  icon: IconifyProps;
  width?: number;
}

const Iconify = forwardRef<SVGSVGElement, Props>(({ icon, width = 20, sx, ...other }, ref) => (
  <Box
    ref={ref}
    component="span" 
    className="component-iconify"
    sx={{ width, height: width, display: 'inline-flex', ...sx }} 
    {...other}
  >
    <Icon icon={icon} style={{ width, height: width }} /> {/* Render Icon here */}
  </Box>
));

export default Iconify;
