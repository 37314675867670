import { HOST_ROUTES } from "../config";

const createCheckOTP = (data?: any) => {
  const { REPORT } = HOST_ROUTES;
  const request = {
    host: "report",
    method: "post",
    path: `${REPORT.paths.check_otp}`,
    body: {
      channel: {
        type: data?.id?.includes("@")?"email":"whatsapp",
        value: data?.id,
      },
      otp: data?.otp,
    },
  };

  return request;
};

export { createCheckOTP as CREATE_CHECK_OTP_API };