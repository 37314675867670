import { API_BASE_URL, SIMPLE_SUMMARY_QUERY } from "../config";
import { CREATE_DATA } from "../api-call";

const readSimplePayoutSummary = ({ id }: { id: string }) => {

  const url = `${API_BASE_URL}${SIMPLE_SUMMARY_QUERY}`;
  const data = {
    id: id
  }
  return CREATE_DATA({ url, data });
};

export { readSimplePayoutSummary as READ_SIMPLE_PAYOUT_SUMMARY };
