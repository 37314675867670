import { API_BASE_URL, MERCHANT_QUERY } from "../config";
import { CREATE_DATA } from "../api-call";

const readPayoutMerchant = ({
  id
}: {
  id: string;
}) => {

  const url = `${API_BASE_URL}${MERCHANT_QUERY}`;
  const data = {
    id: id
  }
  return CREATE_DATA({ url, data });
};

export { readPayoutMerchant as READ_PAYOUT_MERCHANT };