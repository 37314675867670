import { API_BASE_URL, HOST_ROUTES } from "../config";
import { CREATE_DATA } from "../api-call";

const readCheckWebView = ({
  id
}: {
  id: string;
}) => {
  const { SECURITY } = HOST_ROUTES;

  const url = `${API_BASE_URL}${SECURITY.paths.web_view}`;
  const data = {
    id: id
  }
  return CREATE_DATA({ url, data });
};
export { readCheckWebView as READ_CHECK_WEBVIEW_API };
