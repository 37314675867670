
import { HOST_ROUTES } from "../config";
const { REPORT } = HOST_ROUTES;

type OTProps = {
  id?: string;
  type?: string;
  description?: string;
};
const readOTP = ({ id, type, description }: OTProps) => {

  const request = {
    host: "report", 
    method: "post",
    path: `${REPORT.paths.otp}`,
    body: {
      process: {
        type: type, //["access", "process", "checkout", "confirmation"]
        description, //Opcional - Me puedes mandar en process o confirmation el punto especifico que se está haciendo.
      },
      channel: {
        type: id?.includes("@") ? "email" : "whatsapp", //["whatsapp", "email", "sms"]
        value: id, //Este valor se válida, así que si mandas whatsapp y correo te rechazo
      },
    },
  };
  return request;
};

export { readOTP as READ_OTP_API };