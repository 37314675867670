import React from "react";

import {
  Box,
  Link,
  Stack,
  Button,
  Dialog,
  Typography,
  DialogContent,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { MuiOtpInput } from "mui-one-time-password-input";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
const OtpModal = ({
  open,
  close,
  errorMsg,
  contact_id,
  otp,
  handleChange,
  checkOtp,
  isSubmitting,
  otherContact,
  disabledLink,
  userEmail,
  handleOtherContact,
  handleResend,
  generateAlert,
}: any) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      checkOtp();
    }
  };
  return (
    <Dialog
        open={open}
        onClose={close}
        maxWidth="xs"
        fullWidth
        sx={{ overflowX: "hidden" }}
      >

      <Stack
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          overflow: "hidden",
        }}
      >
        <Button
          sx={{
            fontSize: { xs: "24px", md: "28px" },
            textTransform: "none",
            color: "gray",
          }}
          onClick={close}
        >
          x
        </Button>
      </Stack>
      <DialogContent sx={{ overflowX: "hidden" }}>
        <Stack sx={{ textAlign: "center" }} spacing={{ xs: 1, md: 2.5 }} py={2}>
          <Typography>
            <LockOutlinedIcon sx={{ fontSize: 36 }} color="primary" />
          </Typography>
          <Typography variant="h5">¡Código enviado!</Typography>
          {!!errorMsg && (
            <Box sx={{ color: "error.main", typography: "subtitle2" }}>
              {errorMsg}
            </Box>
          )}
          <Typography color={"grey"}>
            Hemos enviado un código de 6 dígitos a tu{" "}
            {isNaN(Number(contact_id)) ? "correo electrónico" : "WhatsApp"}{" "}
            {isNaN(Number(contact_id)) ? (
              contact_id?.split("@")[0].slice(0, 3) + "*****" + contact_id?.split("@")[1]
            ) : (
              contact_id.slice(0, 4) + "****" + contact_id.slice(9)
            )} asociado a esta cuenta.
          </Typography>
          <Typography color={"grey"}>
            Por favor escribelo a continuación:
          </Typography>
          <MuiOtpInput
            value={otp}
            length={6}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            TextFieldsProps={{
              inputProps: {
                inputMode: "numeric",
              },
            }}
            sx={{
              gap: { xs: 1, md: 2 },
              "& .css-1jaky35-MuiFormControl-root-MuiTextField-root input": {
                margin: { xs: "0", md: "0.05rem 0rem" },
                width: { xs: "2rem", md: "4rem" },
                padding: { xs: 1, md: 2 },
                height: "1rem",
                textAlign: "center",
                fontSize: "14px",
              },
            }}
          />
          <LoadingButton
            fullWidth
            color="primary"
            type="submit"
            variant="contained"
            onClick={checkOtp}
            loading={isSubmitting}
            sx={{ textTransform: "none" }}
          >
            Verificar
          </LoadingButton>
          {userEmail && (
            <LoadingButton
              fullWidth
              color="primary"
              variant="outlined"
              loading={otherContact}
              onClick={handleOtherContact}
              sx={{ textTransform: "none" }}
            >
              Enviar código al{" "}
              {isNaN(Number(contact_id)) ? "WhatsApp" : "correo electrónico"}
            </LoadingButton>
          )}
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body2">¿No ha llegado?</Typography>
            <Link
              sx={{
                fontSize: 14,
                color: disabledLink ? "gray" : "primary",
                pointerEvents: disabledLink ? "none" : "auto",
                cursor: disabledLink ? "not-allowed" : "pointer",
              }}
              color="primary"
              underline="always"
              onClick={handleResend}
            >
              Reenviar código
            </Link>
          </Stack>
          <Stack m={0} alignSelf="center">
            <Button
              endIcon={<HelpOutlineIcon />}
              variant="text"
              onClick={generateAlert}
              sx={{
                textTransform: "none",
                textDecoration: "underline",
                padding: 0,
              }}
            >
              Necesito ayuda
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default OtpModal;
