//PROD BASE
const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const SECURITY_URL = process.env.REACT_APP_SECURITY_BASE_URL;
const CHECK_OTP = process.env.REACT_APP_CHECK_OTP;
const ACCESS_QUERY_ = process.env.REACT_APP_ACCESS_QUERY;
const SECURITY_OTP = process.env.REACT_APP_SECURITY_OTP;
const USER_INFO = process.env.REACT_APP_USER_INFO;
const RECONCILED_APPROVAL_LAYOUT =
  process.env.REACT_APP_RECONCILED_APPROVAL_LAYOUT;
const SIMPLE_APPROVAL_LAYOUT = process.env.REACT_APP_SIMPLE_APPROVAL_LAYOUT;

const TOKEN_QUERY = "/v1/merchants/token";
const LOGIN_QUERY = process.env.REACT_APP_LOGIN_QUERY;
const USER_CHECK = process.env.REACT_APP_USER_CHECK;
const WEBVIEW_CHECK = process.env.REACT_APP_WEBVIEW_CHECK;
const RECONCILED_SUMMARY_QUERY = process.env.REACT_APP_RECONCILED_SUMMARY_QUERY;
const RECONCILED_LIST_QUERY = process.env.REACT_APP_RECONCILED_LIST_QUERY;
const SIMPLE_SUMMARY_QUERY = process.env.REACT_APP_SIMPLE_SUMMARY_QUERY;
const SIMPLE_LIST_QUERY = process.env.REACT_APP_SIMPLE_LIST_QUERY;
const MERCHANT_QUERY = process.env.REACT_APP_MERCHANT_QUERY;
const SUCCESS_INFO = process.env.REACT_APP_SUCCESS_INFO

const ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_ALPHA =
  process.env.REACT_APP_ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_ALPHA;
const ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_OMEGA =
  process.env.REACT_APP_ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_OMEGA;

const ENCRYPT_URL = process.env.REACT_APP_ENCRYPT
const DECRYPT_URL = process.env.REACT_APP_DECRYPT

const HOST_ROUTES = {
  SECURITY: {
    host: "security",
    paths: {
      login: LOGIN_QUERY,
      check: USER_CHECK,
      token: TOKEN_QUERY,
      web_view: WEBVIEW_CHECK,
    },
  },
  REPORT: {
    host: "report",
    paths: {
      otp: SECURITY_OTP,
      check_otp: CHECK_OTP,
      user_info: USER_INFO,
      reconciled_approval_layout: RECONCILED_APPROVAL_LAYOUT,
      simple_aproval_layout: SIMPLE_APPROVAL_LAYOUT
    },
  },
};
export {
  API_BASE_URL,
  SECURITY_URL,
  TOKEN_QUERY,
  LOGIN_QUERY,
  USER_CHECK,
  CHECK_OTP,
  ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_ALPHA,
  ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_OMEGA,
  ACCESS_QUERY_,
  RECONCILED_SUMMARY_QUERY,
  RECONCILED_LIST_QUERY,
  SIMPLE_SUMMARY_QUERY,
  SIMPLE_LIST_QUERY,
  MERCHANT_QUERY,
  SUCCESS_INFO,
  HOST_ROUTES,
  ENCRYPT_URL,
  DECRYPT_URL
};
