import axios from "axios";
import { Home } from "../../screens/home/hook";
import {
  Box,
  Container,
  Card,
  Typography,
  Button,
  Snackbar,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";


const Index = () => {
   const {
     openSnackbar,
     snackbarOpen,
     closeSnackbar,
     snackbarSeverity,
     snackbarMessage,
   } = Home();

  const generateAlert = async () => {
    const problemUrl = "https://payments.qentaz.com/v1/help-report";
    const alertInfo = {
      text: `*ALGUIEN REPORTÓ UN PROBLEMA EN EL WEB-VIEW DISPERSIÓN CONCILIADA :fire-elmo:*\n<!channel>\n---------------\n*Cliente*: XXXXXX\n*Fecha*: ${new Date()}\n---------------`,
      parse: false,
      mrkdwn: true,
    };
    try {
      await axios.post(problemUrl, { payload: alertInfo });
      openSnackbar("Hemos notificado al equipo de soporte", "success");
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
      openSnackbar("Error al enviar el mensaje", "error");
    }
  };

  const toDashboard = () => {
    window.location.href = `https://dashboard.trazo.co/`;
  };

  return (
    <Container
      sx={{
        backgroundColor: "#DBFDBA",
        margin: 0,
        padding: {xs: 0, md: "1rem"},
        minWidth: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: {xs: 0, md: "16vh"}
  }}
    >
      <Box
        sx={{
          width: "100%",
          display: {xs: "flex", md: "none"},
          justifyContent: "center",
          margin: "2rem 0",
        }}
      >
        <Box
          component="img"
          sx={{
            width: 70,
            textAlign: "start",
          }}
          alt="Logo"
          src="https://trazo-co.s3.amazonaws.com/logos/Logo+Trazo+Color+Secundario+100px.svg"
        />
      </Box>
      <Card
        sx={{
          width: {xs: "100vw", md: "56vw"},
          padding: "1rem",
          borderRadius: {xs: "none", md: "16px"},
          height: {xs: "auto", md: "auto"},
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: {xs: "none", md: "flex"},
            justifyContent: "flex-start",
            margin: "1rem",
          }}
        >
          <Box
            component="img"
            sx={{
              width: 80,
              textAlign: "start",
            }}
            alt="Logo"
            src="https://trazo-co.s3.amazonaws.com/logos/Logo+Trazo+Color+Secundario+100px.svg"
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              margin: "8% 0",
              textAlign: "center",
              color: "#60CF86",
              fontFamily: "Red Hat Display",
              fontSize: "22px",
              fontWeight: "600",
            }}
          >
            Dispersión no encontrada.
          </Typography>
        </Box>
        <Box
          sx={{
            width: {xs: "100%", md: "92%"},
            display: "flex",
            justifyContent: {xs: "center", md: "flex-end"},
            margin: {xs: "6% 0", md: "6% 2% 2% 6%"},
          }}
        >
          <LoadingButton
            onClick={toDashboard}
            variant="contained"
            sx={{
              border: "none",
              borderRadius: "8px",
              backgroundColor: "#60CF86",
              color: "white",
              fontSize: "14px",
              textTransform: "none",
              width: "9rem",
              height: "2.5rem",
              fontFamily: "Red Hat Display",
              "&:hover": {
                backgroundColor: "white",
                border: "none",
                color: "#60CF86",
              },
            }}
          >
            Ir al Dashboard
          </LoadingButton>
        </Box>
      </Card>

      <Box
        sx={{
          width: "56vw",
          display: "flex",
          justifyContent: { xs: "center", md: "space-between" },
          flexDirection: { xs: "column", md: "row" },
          margin: "1rem 0",
          alignItems: "center"
        }}
      >
        <Box>
          <Button
            onClick={generateAlert}
            variant="text"
            sx={{
              color: "gray",
              fontSize: "16px",
              fontWeight: "500",
              textTransform: "none",
              fontFamily: "Red Hat Display",
              margin: "0",
            }}
            startIcon={<ReportProblemIcon />}
          >
            Reportar un problema
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography
            sx={{
              color: "gray",
              fontSize: "16px",
              fontFamily: "Red Hat Display",
              fontWeight: "500",
            }}
          >
            Con la tecnología de
          </Typography>
          <Box
            component="img"
            sx={{
              width: 60,
              marginLeft: "4px",
              marginTop: "-6px",
            }}
            alt="Logo"
            src="https://trazo-co.s3.amazonaws.com/logos/Logo+Trazo+Color+Secundario+100px.svg"
          />
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={closeSnackbar}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default Index;

