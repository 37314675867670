import axios from "axios";
import { Payout } from "../../screens/payout/hook";
import {
  Box,
  Container,
  Stack,
  Card,
  Typography,
  Button,
  Snackbar,
} from "@mui/material";
import { Icon } from "@iconify/react";
import LoadingButton from "@mui/lab/LoadingButton";
import { DataGrid, GridColDef, GridToolbarContainer } from "@mui/x-data-grid";
import MuiAlert from "@mui/material/Alert";
import TotalRejectedModal from "../../components/totalRejectedModal";
import TotalApprovedModal from "../../components/totalApprovedModal";
import PartialApprovedModal from "../../components/partialApprovedModal";
import OtpModal from "../../components/otpModal";
import Loader from "../../components/loader";

const Index = () => {
  const {
    snackbarOpen,
    snackbarMessage,
    openSnackbar,
    closeSnackbar,
    snackbarSeverity,
    isOtpModalOpen,
    isTotalRejectedModalOpen,
    isTotalApprovedModalOpen,
    isPartialApprovedModalOpen,
    setIsOtpModalOpen,
    setIsTotalRejectedModalOpen,
    setIsTotalApprovedModalOpen,
    setIsPartialApprovedModalOpen,
    closeOtpModal,
    closeTotalRejectedModal,
    closeTotalApprovedModal,
    closePartialApprovedModal,
    otp,
    disabled,
    handleChange,
    checkOtp,
    handleKeyDown,
    handledResend,
    snackbarCodeOpen,
    closeSnackbarCode,
    snackbarCodeSeverity,
    snackbarCodeMessage,
    snackbarRejectedOpen,
    closeSnackbarRejected,
    snackbarRejectedSeverity,
    snackbarRejectedMessage,
    dataCard,
    dataTable,
    selectionModel,
    selectedRows,
    setSelectedRows,
    nonSelectedRows,
    setNonSelectedRows,
    totalApprovedAmountRows,
    totalRejectedAmountRows,
    handleSelectionModelChange,
    isChecked,
    setIsChecked,
    dataMerchant,
    onSubmit,
    errorMsg,
    rejectPayout,
    loading,
    //BOOL CONST VALUE TO BLOCK
    blockConciliation,
    tx_reject_reason,
    setTx_reject_reason,
    tx_reject_channel,
    setTx_reject_channel,
    tx_reject_channel_value,
    setTx_reject_channel_value,
  } = Payout();

  const columns: GridColDef[] = [
    {
      width: 90,
      headerName: "Código",
      field: "external_reference",
    },
    { headerName: "Descripción", width: 160, field: "description" },
    { headerName: "Referencia 1", width: 160, field: "reference_one" },
    { headerName: "Referencia 2", width: 160, field: "reference_two" },
    { headerName: "Referencia 3", width: 160, field: "reference_three" },
    { headerName: "Valor", width: 110, field: "amount" },
    { headerName: "Cliente", field: "payer_name", width: 160 },
    { headerName: "Cobrador", field: "merchant_name", width: 160},
  ];

  const format_number = (x: number) => {
    if (typeof x !== "number" || isNaN(x)) {
      return "0";
    }
    const formattedNumber = x.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    });
    return formattedNumber
      .replace(/\./g, "@")
      .replace(/,/g, ".")
      .replace(/@/g, ",");
  };

  const generateAlert = async () => {
    const problemUrl = "https://payments.qentaz.com/v1/help-report";
    const alertInfo = {
      text: `*ALGUIEN REPORTÓ UN PROBLEMA EN EL WEB-VIEW DISPERSIÓN CONCILIADA :fire-elmo:*\n<!channel>\n---------------\n*Cliente*: ${dataMerchant.merchant_phone
        }\n*Fecha*: ${new Date()}\n---------------`,
      parse: false,
      mrkdwn: true,
    };
    try {
      await axios.post(problemUrl, { payload: alertInfo });
      openSnackbar("Hemos notificado al equipo de soporte", "success");
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
      openSnackbar("Error al enviar el mensaje", "error");
    }
  };

  const openOtpModal = () => {
    onSubmit();
    setIsOtpModalOpen(true);
    setIsTotalApprovedModalOpen(false);
    setIsPartialApprovedModalOpen(false);
  };

  const openTotalRejectedModal = () => {
    setIsTotalRejectedModalOpen(true);
    setNonSelectedRows(dataTable);
    setSelectedRows([]);
  };

  const openTotalApprovedModal = () => {
    setIsTotalApprovedModalOpen(true);
    setSelectedRows(dataTable);
    setNonSelectedRows([]);
  };

  const openPartialApprovedModal = () => {
    setIsPartialApprovedModalOpen(true);
  };

  const formatted_amount = `${format_number(
    parseFloat(dataCard.total_amount)
  )}`;

  const handleSwitchChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const CustomToolbar = ({ selectionModel }: { selectionModel: any }) => (
    <GridToolbarContainer
      sx={{
        width: "100%",
        padding: { xs: "2%", md: "0.5%" },
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "#F0FCF3",
        borderRadius: "8px 8px 0 0",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      {selectionModel?.length > 0 &&
        !(selectionModel?.length === dataTable?.length) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              width: { xs: "100%", md: "100%" },
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: { xs: "flex-start", md: "center" }, width: "100%", marginLeft: "1rem" }}>
              <Typography
                sx={{
                  marginRight: "4%",
                  fontFamily: "Red Hat Display",
                  fontSize: "14px",
                }}
              >
                Valor acumulado:
                <b>${format_number(totalApprovedAmountRows)}</b> | Transacciones a
                aprobar: <b>{selectedRows.length}</b>
              </Typography>
              <Typography
                sx={{
                  marginRight: "4%",
                  fontFamily: "Red Hat Display",
                  fontSize: "14px",
                }}
              >
                Valor acumulado:
                <b>${format_number(totalRejectedAmountRows)}</b> | Transacciones a
                rechazar: <b>{nonSelectedRows.length}</b>
              </Typography>
            </Box>

            <LoadingButton
              onClick={openPartialApprovedModal}
              variant="contained"
              sx={{
                border: "1px solid #0F5C38",
                borderRadius: "8px",
                backgroundColor: "#0F5C38",
                color: "white",
                fontSize: "14px",
                textTransform: "none",
                width: "14rem",
                height: "2.5rem",
                fontFamily: "Red Hat Display",
                display: { xs: "none", md: "block" },
                "&:hover": {
                  backgroundColor: "#F0FCF3",
                  border: "none",
                  color: "#0F5C38",
                },
                marginRight: "0.5rem",
              }}
            >
              Aprobación parcial
            </LoadingButton>
          </Box>
        )}
    </GridToolbarContainer>
  );
  const CustomToolbarWrapper = ({ selectionModel }: { selectionModel: any }) => {
    return <CustomToolbar selectionModel={selectionModel} />;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container
          sx={{
            backgroundColor:
              dataMerchant.secondary_color_brand === "-"
                ? "#F0FCF3"
                : dataMerchant.secondary_color_brand,
            margin: 0,
            minWidth: "100%",
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingLeft: { xs: 0, md: 0 },
            paddingRight: { xs: 0, md: 0 },
            paddingTop: { xs: 0, md: "1rem" },
          }}
        >
          {blockConciliation ||
            dataTable.length === 0 ||
            dataMerchant.status_detail === "inactive" ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: { xs: "flex", md: "none" },
                  justifyContent: "center",
                  margin: "2rem 0",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: 70,
                    textAlign: "center",
                  }}
                  alt="Logo"
                  src={
                    dataMerchant.logo === "-"
                      ? "https://trazo-co.s3.amazonaws.com/logos/Logo+Trazo+Color+Secundario+100px.svg"
                      : dataMerchant.logo
                  }
                />
              </Box>
              <Card
                sx={{
                  width: { xs: "100vw", md: "70vw" },
                  padding: "1rem",
                  borderRadius: { xs: "none", md: "16px" },
                  marginTop: { xs: 0, md: "8%" },
                  height: { xs: "auto", md: "50vh" },
                  minHeight: { xs: "60vh", md: "50vh" },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: { xs: "none", md: "flex" },
                        justifyContent: "center",
                        margin: "1rem 0",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          width: 90,
                          textAlign: "center",
                        }}
                        alt="Logo"
                        src={
                          dataMerchant.logo === "-"
                            ? "https://trazo-co.s3.amazonaws.com/logos/Logo+Trazo+Color+Secundario+100px.svg"
                            : dataMerchant.logo
                        }
                      />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <Typography
                        sx={{
                          margin: {
                            xs: "30% 2rem 0 2rem",
                            md: "4rem 12rem 8rem 12rem",
                          },
                          textAlign: "center",
                          color: dataMerchant.main_color_brand,
                          fontFamily: "Red Hat Display",
                          fontSize: { xs: "18px", md: "22px" },
                          fontWeight: "600",
                        }}
                      >
                        {dataTable.length === 0
                          ? "Esta dispersión no tiene cobros asociados. Para crear una nueva dispersión conciliada, debes volver a tu Dashboard y crearla desde la opción Dispersiones > Dispersión conciliada."
                          : dataMerchant.status_detail === "inactive"
                            ? "Esta dispersión se encuentra inactiva o ya fue procesada anteriormente. Para crear una nueva dispersión conciliada debes volver a tu Dashboard en la opción Dispersiones > Dispersión conciliada."
                            : "Esta dispersión fue bloqueada por seguridad, dado que han superado la cantidad de veces permitidas de ingreso. Para poder aprobarla debes volver a tu Dashboard en la opción Dispersiones > Aprobación."}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
              <Box
                sx={{
                  width: "70vw",
                  display: "flex",
                  justifyContent: { xs: "center", md: "space-between" },
                  flexDirection: { xs: "column", md: "row" },
                  margin: "1rem 0",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Stack direction="row" sx={{ display: { xs: "none", md: "flex" } }}>
                    <Button
                      endIcon={`Ayuda`}
                      sx={{
                        textTransform: "none",
                        color: "gray",
                        fontSize: { xs: "13px", md: "14px" },
                        textDecoration: "underline",
                        padding: 0
                      }}
                      onClick={generateAlert}
                    >
                      <Icon icon="ic:baseline-whatsapp" width="22" height="22" />
                    </Button>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      color: "gray",
                      fontSize: "16px",
                      fontFamily: "Red Hat Display",
                      fontWeight: "500",
                    }}
                  >
                    Con la tecnología de
                  </Typography>
                  <Box
                    component="img"
                    sx={{
                      width: 60,
                      marginLeft: "4px",
                    }}
                    alt="Logo"
                    src="https://trazo-co.s3.amazonaws.com/logos/Logo+Trazo+Color+Secundario+100px.svg"
                  />
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: { xs: "block", md: "none" },
                  margin: "1rem 0",
                  height: "14vh"
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    margin: "0",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: 70,
                      }}
                      alt="Logo"
                      src={dataMerchant.logo}
                    />
                  </Box>
                  <Box
                    sx={{
                      margin: "4% 5%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        color: dataMerchant.main_color_brand,
                        marginBottom: "0",
                        fontWeight: "600",
                        fontFamily: "Red Hat Display",
                        textAlign: "center",
                      }}
                    >
                      Aprobación de dispersion conciliada
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "rgba(33, 43, 54, 1)",
                        margin: "0.5rem 0 0 0",
                        fontFamily: "Red Hat Display",
                        textAlign: "center",
                      }}
                    >
                      ID del proceso: {dataCard.process_id}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "95%",
                  display: { xs: "none", md: "flex" },
                  justifyContent: "space-between",
                  margin: "1rem",
                  maxWidth: "1500px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    margin: "0",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "22px",
                      color: dataMerchant.main_color_brand,
                      marginBottom: 0,
                      fontWeight: "600",
                      fontFamily: "Red Hat Display",
                    }}
                  >
                    Aprobación de dispersión conciliada
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "rgba(33, 43, 54, 1)",
                      marginBottom: "0",
                      fontFamily: "Red Hat Display",
                    }}
                  >
                    ID del proceso: {dataCard.process_id}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    margin: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      width: 90,
                    }}
                    alt="Logo"
                    src={dataMerchant.logo}
                  />
                </Box>
              </Box>

              <Stack
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: { xs: 0, md: "1vh" },
                  borderRadius: 0,
                  minHeight: { xs: "84vh", md: "86vh" },
                  borderBottom: "none",
                  backgroundColor: "white"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    maxWidth: "1500px",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: { xs: "95%", md: "70%" },
                      margin: { xs: "4% 3% 0 3%", md: "2.5% 2% 1% 2%" },
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "42%", md: "38%" },
                        display: "flex",
                        flexDirection: { xs: "column", md: " row" },
                        marginLeft: { xs: "0.5rem", md: 0 },
                      }}
                    >
                      <Box
                        sx={{
                          padding: "0 1%",
                          height: { xs: "4rem", md: "4rem" },
                          width: { xs: "100%", md: "48%" },
                          borderRight: { xs: "none", md: "2px solid gray" },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", md: "14px" },
                            fontWeight: "600",
                            fontFamily: "Red Hat Display",
                            color: "rgba(99, 115, 129, 1)",
                            textAlign: { xs: "left", md: "center" },
                          }}
                        >
                          MONTO A DISPERSAR
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: "16px", md: "20px" },
                            fontWeight: "500",
                            fontFamily: "Red Hat Display",
                            color: "rgba(33, 43, 54, 1)",
                            textAlign: { xs: "left", md: "center" },
                          }}
                        >
                          ${formatted_amount} {dataCard.currency}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          padding: "0 1%",
                          height: "4rem",
                          width: { xs: "100%", md: "52%" },
                          borderRight: { xs: "none", md: "2px solid gray" },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", md: "14px" },
                            fontWeight: "600",
                            fontFamily: "Red Hat Display",
                            color: "rgba(99, 115, 129, 1)",
                            textAlign: { xs: "left", md: "center" },
                          }}
                        >
                          CANTIDAD
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: "16px", md: "20px" },
                            fontWeight: "500",
                            fontFamily: "Red Hat Display",
                            color: "#212B36",
                            textAlign: { xs: "left", md: "center" },
                          }}
                        >
                          {dataCard.total_payouts} Cobros
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: { xs: "62%", md: "60%" },
                        display: "flex",
                        flexDirection: { xs: "column", md: " row" },
                      }}
                    >
                      <Box
                        sx={{
                          padding: "0 1%",
                          height: { xs: "4rem", md: "4rem" },
                          width: { xs: "100%", md: "60%" },
                          borderRight: { xs: "none", md: "2px solid gray" },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", md: "14px" },
                            fontWeight: "600",
                            fontFamily: "Red Hat Display",
                            color: "rgba(99, 115, 129, 1)",
                            textAlign: { xs: "left", md: "center" },
                          }}
                        >
                          DESTINATARIO
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: "16px", md: "20px" },
                            fontWeight: "500",
                            fontFamily: "Red Hat Display",
                            color: "#212B36",
                            textAlign: { xs: "left", md: "center" },
                          }}
                        >
                          {dataCard.receiver
                            .replace(/\w\S*/g, (txt) =>
                              txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                            )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          padding: "0 1%",
                          height: "4rem",
                          width: { xs: "100%", md: "60%" },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", md: "14px" },
                            fontWeight: "600",
                            fontFamily: "Red Hat Display",
                            color: "rgba(99, 115, 129, 1)",
                            textAlign: { xs: "left", md: "center" },
                          }}
                        >
                          CUENTA DESTINO
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: "16px", md: "20px" },
                            fontWeight: "500",
                            fontFamily: "Red Hat Display",
                            color: "#212B36",
                            textAlign: { xs: "left", md: "center" },
                          }}
                        >
                          {dataCard.bank_receiver}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "none", md: "flex" },
                      margin: { xs: "0 2rem 0.5rem 2rem", md: "2rem" },
                    }}
                  >
                    <LoadingButton
                      onClick={openTotalApprovedModal}
                      variant="contained"
                      sx={{
                        border: "1px solid #0F5C38",
                        borderRadius: "8px",
                        backgroundColor: "#0F5C38",
                        color: "white",
                        fontSize: "14px",
                        textTransform: "none",
                        width: "9rem",
                        height: "2.5rem",
                        fontFamily: "Red Hat Display",
                        display:
                          selectionModel.length === 0 ||
                            selectionModel.length === dataTable.length
                            ? "block"
                            : "none",
                        "&:hover": {
                          backgroundColor: "#F0FCF3",
                          border: "none",
                          color: "#0F5C38",
                        },
                      }}
                    >
                      Aprobar todo
                    </LoadingButton>
                    <LoadingButton
                      onClick={openTotalRejectedModal}
                      variant="contained"
                      sx={{
                        border: "1px solid #B71D18",
                        borderRadius: "8px",
                        backgroundColor: "#B71D18",
                        color: "white",
                        fontSize: "14px",
                        textTransform: "none",
                        width: "9rem",
                        height: "2.5rem",
                        margin: "0 1rem",
                        fontFamily: "Red Hat Display",
                        display:
                          selectionModel.length === 0 ||
                            selectionModel.length === dataTable.length
                            ? "block"
                            : "none",
                        "&:hover": {
                          backgroundColor: "#FF563029",
                          border: "none",
                          color: "#B71D18",
                        },
                      }}
                    >
                      Rechazar todo
                    </LoadingButton>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    marginTop: { xs: "0", md: "1.5rem" },
                    marginBottom: "1rem",
                    overflow: "hidden",
                    width: "100%",
                    maxWidth: "1500px",
                  }}
                >
                  <DataGrid
                    slots={{
                      toolbar: () => <CustomToolbarWrapper selectionModel={selectionModel} />,
                    }}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    sx={{
                      "& .MuiDataGrid-columnHeader": {
                        display:
                          selectionModel.length === 0 ||
                            selectionModel.length === dataTable.length
                            ? "block"
                            : "none",
                        backgroundColor: "#F4F6F8",
                        color: "#637381",
                        fontSize: { xs: "12px", md: "15px" },
                        fontWeight: "bold",
                      },
                      borderRadius: "8px",
                      margin: { xs: "0 0.5rem", md: "0 2rem" },
                      fontSize: { xs: "11px", md: "14px" },
                      "& .MuiDataGrid-footerContainer": {
                        display: "none",
                      },
                      flexGrow: 1, // Permite que el DataGrid crezca dentro del contenedor
                      overflow: "unset", // Asegura que no haya scroll interno
                    }}
                    rows={dataTable}
                    columns={columns}
                    pageSizeOptions={[dataTable.length]}
                    checkboxSelection
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={handleSelectionModelChange}
                  />
                </Box>
                {selectionModel.length > 0 &&
                  !(selectionModel.length === dataTable.length) ? (
                  <Box
                    sx={{
                      display: { xs: "flex", md: "none" },
                      justifyContent: "center",
                      margin: "0.5rem 0 2rem 0",
                      width: "100%",
                    }}
                  >
                    <LoadingButton
                      onClick={openPartialApprovedModal}
                      variant="contained"
                      sx={{
                        border: "1px solid #0F5C38",
                        borderRadius: "8px",
                        backgroundColor: "#0F5C38",
                        color: "white",
                        fontSize: "14px",
                        textTransform: "none",
                        width: "90%",
                        height: "2.5rem",
                        fontFamily: "Red Hat Display",
                        "&:hover": {
                          backgroundColor: "#F0FCF3",
                          border: "none",
                          color: "#0F5C38",
                        },
                      }}
                    >
                      Aprobación parcial
                    </LoadingButton>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: { xs: "flex", md: "none" },
                      justifyContent: "space-between",
                      margin: "0.5rem 0 2rem 0",
                      width: "96%",
                    }}
                  >
                    <LoadingButton
                      onClick={openTotalRejectedModal}
                      variant="contained"
                      sx={{
                        border: "1px solid #B71D18",
                        borderRadius: "8px",
                        backgroundColor: "#B71D18",
                        color: "white",
                        fontSize: "14px",
                        textTransform: "none",
                        width: "45%",
                        height: "2.5rem",
                        margin: "0 1rem",
                        fontFamily: "Red Hat Display",
                        display:
                          selectionModel.length === 0 ||
                            selectionModel.length === dataTable.length
                            ? "block"
                            : "none",
                        "&:hover": {
                          backgroundColor: "#FF563029",
                          border: "none",
                          color: "#B71D18",
                        },
                      }}
                    >
                      Rechazar todo
                    </LoadingButton>
                    <LoadingButton
                      onClick={openTotalApprovedModal}
                      variant="contained"
                      sx={{
                        border: "1px solid #0F5C38",
                        borderRadius: "8px",
                        backgroundColor: "#0F5C38",
                        color: "white",
                        fontSize: "14px",
                        textTransform: "none",
                        width: "45%",
                        height: "2.5rem",
                        fontFamily: "Red Hat Display",
                        display:
                          selectionModel.length === 0 ||
                            selectionModel.length === dataTable.length
                            ? "block"
                            : "none",
                        "&:hover": {
                          backgroundColor: "#F0FCF3",
                          border: "none",
                          color: "#0F5C38",
                        },
                      }}
                    >
                      Aprobar todo
                    </LoadingButton>
                  </Box>
                )}
                <Box
                  sx={{
                    width: "96%",
                    display: { xs: "flex", md: "flex" },
                    justifyContent: "space-between",
                    margin: "3rem 1rem 2rem 1rem",
                    padding: "0 0.5rem",
                    alignItems: "center",
                    maxWidth: "1500px"
                  }}
                >
                  <Box>
                    <Stack direction="row" sx={{ display: { xs: "flex", md: "flex" } }}>
                      <Button
                        endIcon={`Ayuda`}
                        sx={{
                          textTransform: "none",
                          color: "gray",
                          fontSize: { xs: "13px", md: "14px" },
                          textDecoration: "underline",
                          padding: 0
                        }}
                        onClick={generateAlert}
                      >
                        <Icon icon="ic:baseline-whatsapp" width="22" height="22" />
                      </Button>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "gray",
                        fontSize: "16px",
                        fontFamily: "Red Hat Display",
                        fontWeight: "500",
                      }}
                    >
                      Con la tecnología de
                    </Typography>
                    <Box
                      component="img"
                      sx={{
                        width: 60,
                        marginLeft: "4px",
                        marginTop: "-6px"
                      }}
                      alt="Logo"
                      src="https://trazo-co.s3.amazonaws.com/logos/Logo+Trazo+Color+Secundario+100px.svg"
                    />
                  </Box>
                </Box>
              </Stack>
            </>
          )}

          <OtpModal
            open={isOtpModalOpen}
            close={closeOtpModal}
            otp={otp}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            checkOtp={checkOtp}
            disabled={disabled}
            handleResend={handledResend}
            generateAlert={generateAlert}
            main_color_brand={dataMerchant.main_color_brand}
            contact_id={dataMerchant.merchant_phone || dataMerchant.merchant_phone}
            user={dataMerchant.first_name}
            errorMsg={errorMsg}
          />
          <TotalRejectedModal
            open={isTotalRejectedModalOpen}
            close={closeTotalRejectedModal}
            total_payouts={dataCard.total_payouts}
            total_amount={`$${formatted_amount}`}
            name_receiver={dataCard.receiver}
            bank_receiver={dataCard.bank_receiver}
            currency={dataCard.currency}
            process_id={dataCard.process_id}
            toSuccess={rejectPayout}
            isChecked={isChecked}
            handleSwitchChange={handleSwitchChange}
            main_color_brand={dataMerchant.main_color_brand}
            secondary_color_brand={dataMerchant.secondary_color_brand}
            tx_reject_reason={tx_reject_reason}
            setTx_reject_reason={setTx_reject_reason}
            tx_reject_channel={tx_reject_channel}
            setTx_reject_channel={setTx_reject_channel}
            tx_reject_channel_value={tx_reject_channel_value}
            setTx_reject_channel_value={setTx_reject_channel_value}
          />
          <TotalApprovedModal
            open={isTotalApprovedModalOpen}
            close={closeTotalApprovedModal}
            total_payouts={dataCard.total_payouts}
            total_amount={`$${formatted_amount}`}
            name_receiver={dataCard.receiver}
            bank_receiver={dataCard.bank_receiver}
            currency={dataCard.currency}
            process_id={dataCard.process_id}
            toOtp={openOtpModal}
            main_color_brand={dataMerchant.main_color_brand}
            secondary_color_brand={dataMerchant.secondary_color_brand}
          />
          <PartialApprovedModal
            open={isPartialApprovedModalOpen}
            close={closePartialApprovedModal}
            total_payouts_approved={selectedRows.length}
            total_payouts_rejected={nonSelectedRows.length}
            total_amount_approved={`$${format_number(totalApprovedAmountRows)}`}
            total_amount_rejected={`$${format_number(totalRejectedAmountRows)}`}
            name_receiver={dataCard.receiver}
            bank_receiver={dataCard.bank_receiver}
            currency={dataCard.currency}
            process_id={dataCard.process_id}
            toOtp={openOtpModal}
            isChecked={isChecked}
            handleSwitchChange={handleSwitchChange}
            main_color_brand={dataMerchant.main_color_brand}
            secondary_color_brand={dataMerchant.secondary_color_brand}
            tx_reject_reason={tx_reject_reason}
            setTx_reject_reason={setTx_reject_reason}
            tx_reject_channel={tx_reject_channel}
            setTx_reject_channel={setTx_reject_channel}
            tx_reject_channel_value={tx_reject_channel_value}
            setTx_reject_channel_value={setTx_reject_channel_value}
          />
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={closeSnackbar}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={closeSnackbar}
              severity={snackbarSeverity}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
          <Snackbar
            open={snackbarCodeOpen}
            autoHideDuration={6000}
            onClose={closeSnackbarCode}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={closeSnackbarCode}
              severity={snackbarCodeSeverity}
            >
              {snackbarCodeMessage}
            </MuiAlert>
          </Snackbar>
          <Snackbar
            open={snackbarRejectedOpen}
            autoHideDuration={6000}
            onClose={closeSnackbarRejected}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={closeSnackbarRejected}
              severity={snackbarRejectedSeverity}
            >
              {snackbarRejectedMessage}
            </MuiAlert>
          </Snackbar>
        </Container>
      )}
    </>
  );
};

export default Index;
