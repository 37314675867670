"use client";

import React from "react";

const Loader = () => {
  return (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    }}
    >
      <div className="animate-border">
        <svg
          className="powered-by-trazo"
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            className="border-path"
            x="1.5"
            y="1.5"
            width="117"
            height="117"
            rx="75"
            fill="#FFF"
            stroke="#09464F"
            stroke-width="1.5"
          />
          <path
            d="M27.3332 74.4001H27.3271L27.2453 74.3975C25.1116 74.2591 24.4057 73.0956 24.1753 72.415C23.5739 70.6369 24.1881 67.2719 34.3607 56.944C39.7731 51.4495 45.4597 46.4819 45.5163 46.4324L47.348 48.5319C47.2915 48.5808 41.6723 53.4908 36.3324 58.9135C26.7232 68.6716 26.7513 71.1744 26.8069 71.4885C26.8641 71.5186 27.0245 71.5844 27.3668 71.6119C27.7506 71.5847 32.8597 71.0699 40.8708 62.6568L42.5316 64.5867C33.3141 74.2675 27.6601 74.4001 27.3332 74.4001Z"
            fill="#09464F"
          />
          <path
            d="M44.7608 60.6857C44.2035 63.472 40.3026 66.8159 40.3026 66.8159L39.188 64.5866C39.188 64.5866 41.3655 62.3871 42.4434 59.8568C42.8614 58.8751 44.0479 58.4536 44.9986 58.9371C46.6133 59.7577 48.424 61.105 48.4278 61.1075C48.4281 61.1079 48.4281 61.1079 48.4281 61.1079C49.1005 61.4897 49.1577 61.6172 49.2772 61.7655C49.6118 62.1797 49.6003 62.8638 49.2734 63.2841C46.8194 66.4388 46.2727 68.9787 46.8194 70.0974C46.9463 70.3556 47.1968 70.6992 47.912 70.6992C50.3413 70.6992 52.9697 67.1805 53.8262 65.7495C54.1953 65.1324 54.9868 64.8454 55.631 65.165C56.3427 65.5181 56.5955 66.3924 56.2011 67.0658C55.8205 67.7154 52.3559 73.4132 47.9127 73.4132H47.9123C46.3286 73.4132 45.0424 72.6386 44.3821 71.2894C43.3762 69.2311 43.9741 66.3384 46.0138 63.1853C46.0161 63.1818 46.0148 63.177 46.011 63.1751C45.3012 62.8348 44.6937 62.3826 44.2173 61.8342C44.2137 61.8301 44.2105 61.8265 44.2096 61.8323"
            fill="#09464F"
          />
          <path
            d="M56.1854 70.2515C56.7776 70.5494 58.7349 70.0438 60.7881 67.4694C61.4039 66.698 62.3376 65.3015 63.2867 63.7338C63.304 63.7047 63.3222 63.6762 63.342 63.6491C63.4209 63.0905 63.4548 62.1548 62.8703 61.8611C62.7 61.7761 62.4859 61.7327 62.2392 61.7327C61.346 61.7327 60.0291 62.3028 58.8368 63.5037C57.7944 64.5531 56.536 65.9835 56.0573 67.7446C55.6559 69.2197 55.8419 70.0783 56.1854 70.2528V70.2515ZM72.8892 64.9638C73.5057 65.3894 73.6616 66.2337 73.2363 66.8504C70.4964 70.828 67.905 73.1011 65.3971 73.1011C65.0283 73.1011 64.666 73.0522 64.3106 72.9534C64.2748 72.9438 64.239 72.933 64.2045 72.9196C64.0374 72.8585 62.5588 72.2689 61.9072 70.5705C61.8816 70.5037 61.8577 70.4366 61.8353 70.3688C60.1154 72.0928 58.2287 73.048 56.5961 73.048C56.0109 73.048 55.4587 72.9259 54.9586 72.6719C54.1517 72.2622 52.3881 70.8935 53.4398 67.0313C54.0933 64.6308 55.7342 62.7773 56.9121 61.5917C59.1938 59.2944 62.0788 58.43 64.0882 59.4366C64.3943 59.5903 64.8612 59.8869 65.268 60.4049L65.5889 59.7964C65.9372 59.1327 66.7581 58.8787 67.4209 59.227C68.0843 59.5756 68.34 60.3959 67.991 61.059C67.1924 62.5779 66.4258 64.0246 65.8458 65.1175C65.4917 65.7857 65.2083 66.3216 65.0299 66.6603C64.3809 67.8954 64.177 68.9113 64.4404 69.599C64.6212 70.0678 64.997 70.2969 65.1114 70.357C66.3184 70.6078 68.4441 69.0238 71.0035 65.3102C71.4285 64.6934 72.2731 64.5384 72.8902 64.9622"
            fill="#09464F"
          />
          <path
            d="M73.56 73.3496C73.1881 73.3496 72.8177 73.1978 72.5502 72.8996C72.0968 72.3951 72.091 71.6444 72.5077 71.135V71.1338C72.5493 71.0826 72.5959 71.0337 72.6458 70.9887H72.6461V70.9871C72.6544 70.98 72.6649 70.9701 72.6774 70.959L82.0175 62.6401C81.4695 62.5305 80.9397 62.38 80.4178 62.232C79.5576 61.9879 78.7446 61.7575 77.9898 61.7575C76.2233 61.7575 75.4544 62.9891 74.3461 64.9979C74.0125 65.6031 73.6978 66.0872 73.3491 66.6931C72.9771 67.3434 72.1463 67.5671 71.4973 67.1939C70.8476 66.8213 70.6233 65.9917 70.9962 65.342C71.2739 64.8582 71.6564 64.2571 71.9702 63.6873C73.1114 61.6188 74.5309 59.0438 77.9898 59.0438C79.122 59.0438 80.1577 59.3371 81.1595 59.6219C82.0134 59.8635 82.8193 60.0923 83.5588 60.0923C84.8089 60.0923 85.9689 59.1186 86.0376 59.0601L86.0491 59.0492C86.6061 58.5526 87.4619 58.6002 87.9601 59.1556C88.4593 59.7113 88.4168 60.5665 87.8627 61.0679C87.8537 61.0749 87.8448 61.0848 87.8326 61.0957L78.4771 69.4279C78.9891 69.5439 79.4598 69.6992 79.9001 69.8443C80.6517 70.0923 81.3001 70.3064 82.0259 70.3064C84.2526 70.3064 86.6585 68.5447 88.3046 65.7092C88.6817 65.0605 89.5138 64.8409 90.16 65.2168C90.8077 65.5938 91.0289 66.4237 90.6515 67.0718C88.4903 70.7963 85.2653 73.0201 82.0265 73.0201C80.8658 73.0201 79.9017 72.7022 79.0514 72.4216C78.3062 72.1758 77.6629 71.9637 76.9515 71.9637C75.6758 71.9637 74.5178 72.9578 74.4666 73.0016V73.0067C74.2084 73.2365 73.886 73.3499 73.5648 73.3499"
            fill="#09464F"
          />
          <path
            d="M95.5932 69.1982C95.5303 69.2611 95.4657 69.3168 95.4002 69.3746C95.3887 69.3848 95.3791 69.3954 95.3667 69.4056L95.366 69.405C94.5994 70.0732 93.7356 70.3895 93.014 70.387C92.0125 70.3828 91.3338 69.8261 91.0602 68.8233C90.5588 67.6566 90.7078 65.9441 91.5677 64.3373C92.8028 62.0311 95.033 60.8196 96.5497 61.6316C96.6692 61.6955 96.7772 61.7735 96.8811 61.8579C97.1223 62.018 97.3096 62.2289 97.4406 62.5139C97.448 62.5312 97.4528 62.5516 97.4601 62.5695C98.0845 63.7331 97.9756 65.5657 97.0594 67.2776C96.7877 67.7848 96.4663 68.235 96.117 68.6265C95.9607 68.8202 95.7914 69.0122 95.5932 69.1982ZM100.031 66.1969L100.07 66.1745C100.547 64.3041 100.497 62.6772 99.9095 61.3884C99.3778 60.2239 98.4344 59.4081 97.1795 59.0288C96.7353 58.8939 96.2643 58.8246 95.7814 58.8227C92.2171 58.8086 88.2324 62.4372 88.212 67.6665C88.197 71.4064 90.6081 73.0905 93.0032 73.1C94.1453 73.1045 95.2628 72.7575 96.2576 72.1299C97.4684 71.3156 99.1266 69.6219 100.031 66.1969Z"
            fill="#09464F"
          />
          <path
            d="M21.4368 59.1895C21.1706 59.1895 20.9009 59.1157 20.6603 58.961C19.9921 58.5319 19.7994 57.6409 20.2286 56.9731C20.3056 56.8532 22.1533 54.0152 25.5943 52.0949C28.8107 50.3 34.0483 48.984 40.7332 52.9073L40.7713 52.9306C40.7898 52.9418 42.9909 54.2926 46.1147 54.7358C50.1903 55.3139 53.8912 54.1408 57.1143 51.2481C57.7052 50.7176 58.614 50.7668 59.1445 51.3577C59.6746 51.9486 59.6254 52.8574 59.0345 53.3879C54.152 57.7694 49.0259 58.0771 45.5855 57.5639C42.0377 57.0347 39.5477 55.5529 39.2636 55.3781C34.8814 52.8111 30.785 52.534 27.087 54.5546C24.2407 56.1102 22.6611 58.5057 22.6454 58.5296C22.3709 58.9572 21.9088 59.1895 21.4368 59.1895Z"
            fill="#09464F"
          />
          <path
            d="M45.5161 46.4324C45.5161 46.4324 48.1212 45.9712 47.3478 48.5319Z"
            fill="#09464F"
          />
        </svg>
      </div>
      <style>{`
        .animate-border {
          position: relative;
        }
        .border-path {
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: border-flow 1.5s linear infinite;
          stroke: #09464F;
        }
        @keyframes border-flow {
          to {
            stroke-dashoffset: 0;
          }
        }
        @media (max-width: 600px) {
          .powered-by-trazo {
            width: 90px;
            height: 90px;
          }
        }
      `}</style>
    </div>
  );
};

export default Loader;