import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

const PartialApprovedModal = ({
  open,
  close,
  total_payouts_approved,
  total_payouts_rejected,
  total_amount_approved,
  total_amount_rejected,
  name_receiver,
  bank_receiver,
  currency,
  process_id,
  toOtp,
  isChecked,
  handleSwitchChange,
  main_color_brand,
  secondary_color_brand,
  tx_reject_reason,
  setTx_reject_reason,
  tx_reject_channel,
  setTx_reject_channel,
  tx_reject_channel_value,
  setTx_reject_channel_value
}: any) => {
  return (
    <Modal open={open} onClose={close}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "72vw", md: 700 },
          bgcolor: "background.paper",
          borderRadius: 3,
          boxShadow: 24,
          padding: { xs: "4%", md: "1% 1% 1% 1%" },
        }}
      >
        <Box
          sx={{
            width: "93%",
            margin: 0,
            padding: 0,
            top: "0.5%",
            textAlign: "end",
            display: "flex",
            justifyContent: "end",
            position: "absolute"
          }}
        >
          <Button
            onClick={close}
            sx={{
              color: "gray",
              minWidth: "0",
            }}
          >
            <CloseIcon fontSize="medium" sx={{ margin: "0" }} />
          </Button>
        </Box>
        <Box
          sx={{
            margin: "6% 2% 2% 2%",
            width: "92%",
            padding: "2%",
            display: "flex",
            borderRadius: "8px",
            color: " var(--dark-light-info, #006C9C)",
            backgroundColor: "var(--info-8, #00B8D914)",
            border: "1px solid var(--info-16, #00B8D929)",
          }}
        >
          <ErrorIcon
            sx={{
              width: "18px",
              margin: "0 2% 0 0",
            }}
          />
          <Typography
            sx={{
              width: "100%",
              margin: "0",
              fontSize: { xs: "16px", md: "18px" },
              fontWeight: "500",
              fontFamily: "Red Hat Display",
              justifyContent: "space-between",
            }}
          >
            Verifica tu información
          </Typography>
        </Box>

        <Typography
          fontWeight={400}
          sx={{
            margin: "4% 2% 2% 2%",
            fontFamily: "Red Hat Display",
            color: "#637381",
            fontWeight: "600",
            fontSize: { xs: "14px", md: "16px" }
          }}
        >
          Verifica tus dispersiones a aprobar, las que no seleccionaste, al
          hacer click en "Continuar" se rechazaran automaticamente.
        </Typography>
        <Box
          sx={{
            width: { xs: "100%", md: "98%" },
            marginLeft: "2%",
            marginBottom: 0
          }}
        >
          <Typography
            fontWeight={400}
            sx={{
              margin: "0",
              fontFamily: "Red Hat Display",
              color: "#637381",
              fontSize: { xs: "14px", md: "16px" }
            }}
          >
            Dispersión a aprobar:
          </Typography>
        </Box>

        <Box
          sx={{
            margin: "1% 2% 3% 2%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 1
          }}
        >

          <Box
            sx={{
              width: { xs: "100%", md: "30%" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                fontWeight: "600",
                color: "#637381",
              }}
            >
              CANTIDAD:
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                color: "#637381",
              }}
            >
              {total_payouts_approved}
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "30%" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                fontWeight: "600",
                color: "#637381",
              }}
            >
              MONTO:
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                color: "#637381",
              }}
            >
              {total_amount_approved} {currency}
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "45%" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                fontWeight: "600",
                color: "#637381",
              }}
            >
              DESTINATARIO:
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                color: "#637381",
              }}
            >
              {name_receiver}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            margin: "0 2% 3% 2%",
            display: "flex",
            borderBottom: "1px solid gray",
            paddingBottom: "3%",
            flexDirection: { xs: "column", md: "row" },
            gap: 1
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "28%" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                fontWeight: "600",
                color: "#637381",
              }}
            >
              CUENTA DESTINO:
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                color: "#637381",
              }}
            >
              {bank_receiver}
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "60%" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                fontWeight: "600",
                color: "#637381",
              }}
            >
              ID DEL PROCESO:
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                color: "#637381",
              }}
            >
              {process_id}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "100%" },
            marginLeft: "2%"
          }}
        >
          <Typography
            fontWeight={400}
            sx={{
              margin: "0",
              fontFamily: "Red Hat Display",
              color: "#637381",
              fontSize: { xs: "14px", md: "16px" }
            }}
          >
            Dispersión a rechazar:
          </Typography>
        </Box>
        <Box
          sx={{
            margin: "1% 2% 1% 2%",
            display: "flex",
            borderBottom: "1px solid gray",
            paddingBottom: "3%",
            flexDirection: { xs: "column", md: "row" },
            gap: 1
          }}
        >

          <Box
            sx={{
              width: { xs: "100%", md: "28%" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                fontWeight: "600",
                color: "#637381",
              }}
            >
              CANTIDAD:
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                color: "#637381",
              }}
            >
              {total_payouts_rejected}
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "35%" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                fontWeight: "600",
                color: "#637381",
              }}
            >
              MONTO:
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontFamily: "Red Hat Display",
                color: "#637381",
              }}
            >
              {total_amount_rejected} {currency}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2%",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Typography
            fontWeight={400}
            sx={{
              marginTop: "1%",
              fontFamily: "Red Hat Display",
              fontSize: { xs: "14px", md: "16px" }
            }}
          >
            Indicanos el motivo del rechazo
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isChecked}
                onChange={handleSwitchChange}
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: main_color_brand,
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: main_color_brand,
                  },
                }}
              />
            }
            label="Enviar notificación de rechazo"
            sx={{
              "& .MuiFormControlLabel-label": {
                fontFamily: "Red Hat Display",
                fontSize: { xs: "12px", md: "14px" },
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            margin: "0 2% 4% 2%",
            flexDirection: { xs: "column", md: "row" },
            gap: 1
          }}>
          <FormControl
            variant="outlined"
            fullWidth
            sx={{
              padding: 0, margin: "0 1% 0 0",
              width: "100%"
            }}
          >
            <InputLabel
              htmlFor="outlined-input"
              sx={{
                fontFamily: "Red Hat Display",
                "&.Mui-focused": {
                  color: "gray",
                  fontSize: { xs: "16px", md: "18px" },
                },
              }}
            >
              Motivo
            </InputLabel>
            <OutlinedInput
              id="outlined"
              label="Motivo"
              placeholder="Escribe el motivo aquí"
              value={tx_reject_reason}
              onChange={(e) => setTx_reject_reason(e.target.value)}
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                borderRadius: "8px",
              }}
            />
          </FormControl>
          <Box display="flex" flexDirection={"row"} sx={{ width: isChecked ? "100%" : "0" }} gap={1}>
            <FormControl
              sx={{
                width: "70%",
                marginRight: "1%",
                display: isChecked ? "flex" : "none"
              }}
            >
              <InputLabel
                id="demo-simple-select-label"
                sx={{ fontFamily: "Red Hat Display" }}
              >
                Canal
              </InputLabel>
              <Select
                sx={{
                  borderRadius: "8px",
                }}
                label="Canal"
                value={tx_reject_channel}
                onChange={(e) => setTx_reject_channel(e.target.value)}
              >
                <MenuItem value="whatsapp">WhatsApp</MenuItem>
                <MenuItem value="email">Correo</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              sx={{
                width: "70%",
                display: isChecked ? "flex" : "none"
              }}
            >
              <InputLabel
                htmlFor="outlined-input"
                sx={{
                  fontFamily: "Red Hat Display",
                  "&.Mui-focused": {
                    color: "gray",
                    fontSize: { xs: "16px", md: "18px" },
                  },
                }}
              >
                Número
              </InputLabel>
              <OutlinedInput
                id="outlined"
                label="Número"
                placeholder="Escribe el número aquí"
                value={tx_reject_channel_value}
                onChange={(e) => setTx_reject_channel_value(e.target.value)}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  borderRadius: "8px",
                }}
              />
            </FormControl>
          </Box>

        </Box>
        <Box
          sx={{
            width: "98%",
            display: "flex",
            justifyContent: "end",
            marginTop: "-2%",
            marginBottom: "2%",
            gap: "6%"

          }}
        >
          <Button
            variant="contained"
            onClick={close}
            sx={{
              maxHeight: "40px",
              minHeight: "35px",
              backgroundColor: secondary_color_brand,
              mt: 2,
              width: { xs: "30%", md: "16%" },
              textTransform: "none",
              fontFamily: "Red Hat Display",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: "400",
              color: main_color_brand,
              borderRadius: "8px",
              height: { xs: "4vh", md: "5vh" },
              marginRight: "3%",
              "&:hover": {
                backgroundColor: "gray",
                border: "none",
                color: "white",
              },
            }}
          >
            Volver
          </Button>
          <Button
            variant="contained"
            onClick={toOtp}
            sx={{
              maxHeight: "40px",
              minHeight: "35px",
              backgroundColor: main_color_brand,
              mt: 2,
              width: { xs: "30%", md: "16%" },
              textTransform: "none",
              fontFamily: "Red Hat Display",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: "400",
              borderRadius: "8px",
              height: { xs: "4vh", md: "5vh" },
              "&:hover": {
                backgroundColor: "gray",
                border: "none",
                color: "white",
              },
            }}
          >
            Continuar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PartialApprovedModal;
